

.fox-product{
  .render-item{
    // margin-bottom: 10px;
    // overflow: hidden;
    // border-radius: 10px;
    // // :hover{
    // //   --tw-shadow: 0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1);
    // //   --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    // // }
    // + :first-child{

    // box-shadow: 0 0 10px rgba(0,0,0,0.1);
    // }
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .render-item{
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      .card:not(a){
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        .details_wapper_product{
          display: flex;
          flex: 1;
          width: 100%;
        }
      }
    }
  }
}