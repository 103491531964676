.audio-box{
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999;
  .own_icon{
    width: 40px;
    height: 40px;
    transition: all 2s;
  }
  .animated-song{
    animation: load infinite 2s;
  }
}

@keyframes load {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(360deg);
  }
}