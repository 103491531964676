.fox_galery_slider{
  * img{
    object-fit: cover !important;
  }
  .slider_wrapper{
    overflow: hidden;
    width: 100%;
    .slider_inside{
      padding-top: 100%;
      position: relative;
      width: 100%;
      height: 0;
      img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 8s;
        opacity: 0;
      }
    }
  }
}