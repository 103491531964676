body{
  --onyx-dark:rgb(61 64 73);
}
.share_page{
  min-height: 100vh;
  background-color: #fff;
  .lazyfox_input_box_value {
    box-shadow: 0 0 4px rgba(0,0,0,.4) !important;
  }
  .button_icons{
    padding: 0;
  }
}