.modal-window{
    position: fixed;
    z-index: 9999;
    background-color: rgba(0,0,0, .3);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .modal-window-content{
        position: relative;
        z-index: 2;
        transition: all .2s;
        transform: scale(0);
        min-width: 200px;
        margin: auto;
        max-height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        padding-top: 25px;
        background-color: #fff;
        border-radius: 10px;
        .modal-header{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 10px;
        }
    }
    .modal-window-overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; 
        z-index: 1;
    }
    &.show{
        .modal-window-content{
            transform: scale(1);
        }
    }
    .modal-content-wrapper{
        background-color: #fff;
        display: block;
        width: 100%;
        min-height: 100px;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    .modal-close{
        cursor: pointer;
    }
}

.app-disabled{
    overflow: hidden;
}

.active-scroll{
    overflow-y: auto;
}