.navigation_bar{
  position: fixed;
  width: 100vw;
  padding: 0 20px 10px;
  bottom: 0;
  left: 0;
  z-index: 9999;
  text-align: center;
  .wrapper_bar{
    background: #fff;
    padding: 10px;
    border-radius: 100px;
    display: inline-block;

    box-shadow: 0 0 10px rgba(0,0, 0,.3);
    ul{
      padding-left: 0;
      margin-bottom: 0;
      text-align: center;
      li{
        display: inline-block;
        font-size: 14px;
        list-style-type: none;
        padding: 0 !important;
        text-transform: uppercase;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
        a{
          display: flex;
          align-items: center;
          padding: 7px;
          text-decoration: none;
          color: #6d6d6d;
          font-size: 10px;
          border-radius: 100%;
          cursor: pointer;
          transition: all .3s;
          &:hover{
            background-color: var(--bg-nav-bar);
            svg{
              fill: #fff;
            }
          }
        }
        .active{
          background-color: var(--bg-nav-bar);
          svg{
            fill: #fff;
          }
        }
      }
    }
  }
}