

.react-photo-album--photo{
  // &.animated{
  //   display: none !important;
  // }

  display: none !important;
  &.animated {
    &.zoomIn{
      display: block !important;
      // opacity: 1 !important;
      // transform: translate(0) !important;
    }
    &.fadeUp{
      display: block !important;
      // opacity: 1 !important;
      // transform: translate(0) !important;
    }
  }
}
.animated_hide{
  opacity: 0 !important;
  
}
.animated{
  // opacity: 1 !important;
  &.zoomIn{
    display: block !important;
    opacity: 1 !important;
    // transform: translate(0) !important;
    transform: translateZ(0) !important;
  }
  &.fadeUp{
    display: block !important;
    opacity: 1 !important;
    // transform: translate(0) !important;
    transform: translateZ(0) !important;
  }
}
