.fox_comments{
  display: flex;
  justify-content: center;
  width: 100%;
  .comment_wrapper{
    max-width:720px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
  }
  .comments_form{
    background-color: transparent;
    padding: 10px;
  }
  .value_title{
    background-color: transparent;
  }
  * img{
    object-fit: cover !important;
  }
  .action_button{
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
    .button_icons{
      width: 200px;
      .button-render{
        background-color: #5A4431;
      }
    }
  }
  .comment_list{
    padding: 10px;
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
    .comment_content{
      .comment_info{
        display: flex;
        .own_icon{
          width: 40px;
          height: 40px;
          background-color: #f1f1f1;
          border: 2px solid #afafaf;
          border-radius: 50%;
          padding: 3px;
        }
        .right_path{
          padding-left: 20px;
          flex: 1;
          .commenter_name{
            font-weight: 600;
            margin-bottom: 5px;
            display: block
          }
          .comment_text{
            font-size: 14px;
            line-height: 1.5em;
          }
        }
      }
      .comment_actions{
        display: flex;
        justify-content: flex-end;
        font-size: 10px;
      }
    }
  }
}
input, textarea{
  &:focus{
    --tw-ring-shadow: transparent !important
  }
}