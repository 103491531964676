.fox-galery{
  * img{
    object-fit: cover !important;
  }
}

.box_gallery{
  width: 100% !important;
  display: flex;
  // flex-wrap: wrap;
  .group_colum{
    // display: flex;
    // flex-wrap: wrap;
    width: 33.33%;
    .img_galery{
      width: 100%;
      padding: 5px;
      img{
  
        width: 100%;
        height: auto;
        object-fit: contain !important;
      }
    }
  }
}