.box-loader {
    position: relative;
    background-color: #fff;
    padding: 20px 15px;
    flex: 1;
    margin-right: 3px;
    font-weight: 500;
    min-height: 70px;
    margin-top: 3px;
    min-height: 70vh;
    background-color: #f1f1f1;
    .load-bar{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend .loader-anim;
        &:last-child{
            margin-right: 0px;
        }
    }
}