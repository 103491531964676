html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
 margin: 0;
 padding: 0;
 border: 0;
//  font-size: 100%;
 line-height: 1.5em;
 vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
 display: block;
}
html, body {
 box-sizing: border-box !important; box-sizing: -webkit-border-box !important;
 background-color: #f1f1f1;
 margin: 0;
 padding: 0;
 overflow: hidden;
}
*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important; box-sizing: -webkit-border-box !important;
  // font-size: 16px;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
  // @media(max-width:620px){
  //   font-size: 13px;
  // }
}
body {
 line-height: 1;
 overscroll-behavior-y: none;
 overflow-y: auto;
 max-height: 100vh;
 font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;
}
ol, ul {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
table {
 border-collapse: collapse;
 border-spacing: 0;
}
a{
  text-decoration: none; cursor: pointer;
}

button{
  background-color: transparent;
    border: 0;
    /* padding: 0; */
    margin: 0;
}
