
.button_icons{
  height: 100%; display: flex; padding: 0 5px;
  @extend .no_select;
  .button_icons_box{
    min-width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; position: relative;
    @media(max-width: 425px){
      min-width: 50px;
    }
    // @media(max-width: 400px){
    //   min-width: 40px;
    // }
    cursor: pointer;
    .button_icons_bandle{
      position: relative;
      .icons{
        fill: #b7c1c7; height: 24px; width: 24px; margin-bottom: 0px;cursor: pointer;transition:  0.4s all;
      }
      .alert_info{
        position: absolute;
        right: -6px;
        top: -3px;
        padding: 2px;
        min-width: 19px;
        min-height: 15px;
        border-radius: 10px;
        background-color: $notifyAlert;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $primaryColor;
        .alert_info_text{
          font-size: 9px;
          line-height: 11px;
          color: #fff;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1px;
        }
      }
    }
    .text{
      color: #b7c1c7; font-size: 12px; font-weight: 400;display: block;cursor: pointer;transition:  0.4s all;
      @media(max-width: 400px){
        font-size: 11px;
      }
    }
    &:hover{
      .icons,.text{
        fill: #fff;color: #fff;
      }
    }
  }
  .active{
    .icons,.text{
      fill: #fff !important;color: #fff !important;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: -4px;
      left: auto; right: auto;
      height: 2px; background-color: #fff; width: 100%; margin: auto;
    }
  }
}

.button_icons_dark{
  @extend .button_icons;
  display: flex;
  align-self: flex-end;
  @extend .no_select;
  .button_icons_box{
    .button_icons_bandle{
      .icons{
        fill: $notActive;
      }
      .alert_info{
        border: 2px solid #fff;
      }
    }
    .text{
      color: $notActive; font-weight: 600;
    }
    &:hover{
      .icons,.text{
        fill: $primaryColor ;color: $primaryColor;
      }
    }
  }
  .active{
    .icons,.text{
      fill: $primaryColor !important;color: $primaryColor !important;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: -6px;
      left: auto; right: auto;
      height: 2px; background-color: #fff; width: 100%; margin: auto;
    }
  }
}

.button_icons_small{
  @extend .no_select;
  @media (max-width:700px) {
    height: 34px;widows: 34px;
  }
}

.desktop-items{
  @media(max-width: 911px){
    display: none !important;
  }
}

.notification_btn{
  @extend .no_select;
  @media(max-width: 911px){
    //margin-bottom: -4px;
    .button_icons_bandle{
      height: 30px;
    }
    .text{
      display: none !important;
    }
    .icons{
      height: 30px !important;
      width: 30px !important;
    }
  }
}

.secondary_button_icons{
  @extend .no_select;
  .button_icons_bandle{
    height: 30px;
  }
  .button_icons_box{
    min-width: 30px;
    margin-right: 5px;
    //margin-bottom: -4px;
    flex-direction: row;
    .icons{
      height: 30px !important;
      width: 30px !important;
    }
  }
}

.anim_zoom_out{
  .icons,.own_icon, *:first-child{
    animation: anim_zoom_out .4s linear;
  }
  .animate_btn{
    transition: .2s all linear;
    &:hover{
      opacity: .8;
    }
  }
}
.anim_zoom_in{
  .icons,.own_icon, *:first-child{
    animation: anim_zoom_in .3s linear;
  }
  .animate_btn{
    transition: .2s all linear;
    &:hover{
      opacity: .8;
    }
  }
}

.button-icon{
  background-color: var(--main-color-dark);
  color: #fff;
  padding: 5px;
  text-align: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  cursor: pointer;
  opacity: .8;
  fill: #fff;
  color: #fff;
  &:hover{
    opacity: 1;
  }
  &.button-red{
    background-color: var(--notify-alert) !important;
  }
}


.button-render{
  background-color: var(--main-color-dark);
  color: #fff;
  padding: 15px;
  text-align: center;
  justify-content: center;
  width: 100%;
  min-width: 150px;
  border-radius: 5px;
  cursor: pointer;
  opacity: .8;
  &:hover{
    opacity: 1;
  }
}

@keyframes anim_zoom_out{
  0%{ transform: scale(1);}
  20%{ transform: scale(1.6);}
  50%{ transform: scale(.6);}
  70%{ transform: scale(1.2);}
  100%{ transform: scale(1);}
}
@keyframes anim_zoom_in{
  0%{ transform: scale(1);}
  20%{ transform: scale(1.6);}
  50%{ transform: scale(1);}
  70%{ transform: scale(.6);}
  100%{ transform: scale(1);}
}

.disabled{
  opacity: .7;
}