// *{
//   line-height: 1.3em;
// }
// a{
//   color: initial;
// }
// .content_pages{
//   padding-top: 52px;
//   .content_value{
//     min-height: 100vh;
//     position: relative;
//     display: flex;
//     //flex-direction: column;
//     justify-content: space-between;
//   }
//   @media(max-width: 991px){
//     padding-bottom: 52px;
//   }
// }
.own_icon{
  width: 20px;
  height: 20px;
}
.full_wide{
  width: 100%;
}
.p_main{
  padding: 1em;
}
.transition{
  transition: .3s all;
}
.no_select{
  // user-select: none !important;
  // -webkit-tap-highlight-color: transparent !important;
  // -webkit-touch-callout: none !important;
  // -webkit-user-select: none !important;
  // -khtml-user-select: none !important;
  // -moz-user-select: none !important;
  // -ms-user-select: none !important;
  // user-select: none !important;
  // *{
  //     -webkit-tap-highlight-color: transparent !important;
  //     -webkit-touch-callout: none !important;
  //     -webkit-user-select: none !important;
  //     -khtml-user-select: none !important;
  //     -moz-user-select: none !important;
  //     -ms-user-select: none !important;
  //     user-select: none !important;
  // }

  @media(max-width: 768px){
    cursor: none !important;
    *{
      cursor: none !important;
    }
  }
}


.welcome_text{
  width: 100%;
    height: 80vh;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 25px;
}

.hide_block{
  display: none !important;
}

.share_page{
  justify-content: center;
  display: flex;
  align-items: center;
  .share_inside{
    max-width: 720px;
    padding: 20px;
    .button_icons{
      margin-top: 20px;
    }
  }
}