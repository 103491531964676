.dashboard-home{
    width: 100%;
    min-height: 100vh;
    background-color: var(--main-bg);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .search-bar{

    }
    .file-name{
        display: flex;
        align-items: center;
        .icon-doc{
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--main-color);
            border-radius: 5px;
            .own_icon{
                width: 30px;
                height: 30px;
                fill: #fff;
            }
        }
        span{
            margin-left: 10px;
            flex: 1;
        }
    }
}