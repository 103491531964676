.sign-in{
    width: 100%;
    height: 100vh;
    background-color: var(--main-color-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    .sing-in-box{
        padding: 10px;
        max-width: 480px;
        margin: auto;
        padding: 30px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        width: 100%;
        .inside-box{
            max-width: 480px;
            width: 100%;
        }
        .form-control{
            justify-content: flex-end;
            margin-top: 20px;
        }
    }
}