
/* CSS HEX */
body{
//   --davys-gray: #4D4846ff;
// --jet: #302B29ff;
// --eerie-black: #1E1D1Cff;
// --night: #121212ff;
// --onyx: #3C3F46ff;

// /* CSS HSL */
// --davys-gray: hsla(17, 5%, 29%, 1);
// --jet: hsla(17, 8%, 17%, 1);
// --eerie-black: hsla(30, 3%, 11%, 1);
// --night: hsla(0, 0%, 7%, 1);
// --onyx: hsla(222, 8%, 25%, 1);

// /* SCSS HEX */
// --davys-gray: #4D4846ff;
// --jet: #302B29ff;
// --eerie-black: #1E1D1Cff;
// --night: #121212ff;
// --onyx: rgb(110 118 137);
// --onyx-dark:rgb(61 64 73);

// /* SCSS HSL */
// --davys-gray: hsla(17, 5%, 29%, 1);
// --jet: hsla(17, 8%, 17%, 1);
// --eerie-black: hsla(30, 3%, 11%, 1);
// --night: hsla(0, 0%, 7%, 1);
// --onyx: hsla(222, 8%, 25%, 1);
// --bg-nav-bar:rgb(61 64 73);

// /* SCSS RGB */
// --davys-gray: rgba(77, 72, 70, 1);
// --jet: rgba(48, 43, 41, 1);
// --eerie-black: rgba(30, 29, 28, 1);
// --night: rgba(18, 18, 18, 1);
// --onyx: rgba(60, 63, 70, 1);

// /* SCSS Gradient */
// --gradient-top: linear-gradient(0deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-right: linear-gradient(90deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-bottom: linear-gradient(180deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-left: linear-gradient(270deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-top-right: linear-gradient(45deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-bottom-right: linear-gradient(135deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-top-left: linear-gradient(225deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-bottom-left: linear-gradient(315deg, #4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);
// --gradient-radial: radial-gradient(#4D4846ff, #302B29ff, #1E1D1Cff, #121212ff, #3C3F46ff);



// color-scheme: dark;
--bs-body-color: #dee2e6;
--bs-body-color-rgb: 222, 226, 230;
--bs-body-bg: #212529;
--bs-body-bg-rgb: 33, 37, 41;
--bs-emphasis-color: #fff;
--bs-emphasis-color-rgb: 255, 255, 255;
--bs-secondary-color: rgba(222, 226, 230, 0.75);
--bs-secondary-color-rgb: 222, 226, 230;
--bs-secondary-bg: #343a40;
--bs-secondary-bg-rgb: 52, 58, 64;
--bs-tertiary-color: rgba(222, 226, 230, 0.5);
--bs-tertiary-color-rgb: 222, 226, 230;
--bs-tertiary-bg: #2b3035;
--bs-tertiary-bg-rgb: 43, 48, 53;
--bs-primary-text-emphasis: #6ea8fe;
--bs-secondary-text-emphasis: #a7acb1;
--bs-success-text-emphasis: #75b798;
--bs-info-text-emphasis: #6edff6;
--bs-warning-text-emphasis: #ffda6a;
--bs-danger-text-emphasis: #ea868f;
--bs-light-text-emphasis: #f8f9fa;
--bs-dark-text-emphasis: #dee2e6;
--bs-primary-bg-subtle: #031633;
--bs-secondary-bg-subtle: #161719;
--bs-success-bg-subtle: #051b11;
--bs-info-bg-subtle: #032830;
--bs-warning-bg-subtle: #332701;
--bs-danger-bg-subtle: #2c0b0e;
--bs-light-bg-subtle: #343a40;
--bs-dark-bg-subtle: #1a1d20;
--bs-primary-border-subtle: #084298;
--bs-secondary-border-subtle: #41464b;
--bs-success-border-subtle: #0f5132;
--bs-info-border-subtle: #087990;
--bs-warning-border-subtle: #997404;
--bs-danger-border-subtle: #842029;
--bs-light-border-subtle: #495057;
--bs-dark-border-subtle: #343a40;
--bs-heading-color: inherit;
--bs-link-color: #6ea8fe;
--bs-link-hover-color: #8bb9fe;
--bs-link-color-rgb: 110, 168, 254;
--bs-link-hover-color-rgb: 139, 185, 254;
--bs-code-color: #e685b5;
--bs-highlight-color: #dee2e6;
--bs-highlight-bg: #664d03;
--bs-border-color: #495057;
--bs-border-color-translucent: rgba(255, 255, 255, 0.15);
--bs-form-valid-color: #75b798;
--bs-form-valid-border-color: #75b798;
--bs-form-invalid-color: #ea868f;
--bs-form-invalid-border-color: #ea868f;
--bg-nav-bar: #816675;
}




.button_icons {
  .button-render{
    background-color: var(--onyx-dark) !important;
  }
}

.button-undangan{
  background-color: var(--onyx-dark) !important;
}

.header_text{
  color: #fff;
  margin-left: 10px;
  text-transform: uppercase;
  font-family: "Cinzel", Sans-serif;
}

.header_text_cutom{
  color: #fff;
  margin-left: 10px;
  font-family: "Great Vibes", cursive;
  margin-bottom: 10px;
}

.dancing_script{
  font-family: "Dancing Script", cursive !important; 
  // font-size: 18px !important;
  margin-bottom: 10px !important;
}

.just-another-hand {
  font-family: "Just Another Hand", cursive  !important; 
  font-style: normal;
}
.greate_vides{
  color: #fff;
  margin-left: 10px;
  font-family: "Great Vibes", cursive !important;
  margin-bottom: 10px;
}

.pacifico {
  font-family: "Pacifico", cursive !important; 
  font-weight: 400;
  font-style: normal;
}

.oleo-script {
  font-family: "Oleo Script", system-ui  !important; 
  font-style: normal;
}

.berkshire-swash {
  font-family: "Berkshire Swash", serif !important; 
  font-style: normal;
}

.arizonia {
  font-family: "Arizonia", cursive  !important; 
  font-style: normal;
}

.cookie {
  font-family: "Cookie", cursive  !important; 
  font-style: normal;
}
.leckerli-one {
  font-family: "Leckerli One", cursive !important; 
  font-style: normal;
}

.playball {
  font-family: "Playball", cursive !important; 
  font-style: normal;
}



.font-esthetic{
  @extend .playball;
}


body{
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.footer{
  .container{
    padding-bottom: 100px;
  }
}

.loading-page {
  & > div{
    height: 100vh;
  }
}

.fox_icon{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.btn.btn-light.shadow.rounded-4.mt-4, .btn.btn-outline-light.btn-sm.shadow.rounded-pill.px-3.my-2, .btn.btn-outline-light.btn-sm.rounded-pill.shadow-sm.mb-2.px-3  {
  display: inline-flex;
  align-items: center;
  margin: auto;
}


.copy-btn{
  margin-top: 15px;
}

.col-5.card-body.border{
  .img-fluid.w-50.rounded  {
    height: 30px;
    width: auto;
    object-fit: contain;
  }
}

footer{
  padding-bottom: 50px;
}

*, ::after, ::before{
  &.dark_color, &.dark_colors{

      fill: #816675 !important;
  }
}

.dark_color, .dark_colors{
  fill: var(--bg-nav-bar) !important;
  *{
    fill: var(--bg-nav-bar) !important;
  }
}

.dark_color, .dark_colors{
  &::after{
    
    fill: var(--bg-nav-bar) !important;
  }
}


.loading-page {
  div{
    div{
      & > h1:first-child{
        // font-size: 18px;
      }
    }
  }
}

.params{
  margin-left: 8px;
}

.lazyfox_input_box .lazyfox_select_box_value, .lazyfox_input_box .lazyfox_input_box_value{
  box-shadow: 0 0 4px rgb(255 255 255 / 30%);
}

.fox_comments .action_button .button_icons .button-render{
  background-color: var(--bg-nav-bar) !important;
}

.loading-page{
  background-color: transparent !important;
}
.loading-page .reverse{
  background-color: var(--bg-nav-bar) !important;
  transition: 1.5s all ease-in-out;
}
.loading-page .slideInDown{
  transform: translateY(-100%);
  opacity: 0;
}

.fox-icon_logi{
  display: inline-flex;
  width: 15px;
  height: 15px;
}

[fox-data=world]{
  display: inline-flex !important;
}

.hallo-test{
  display: flex;
}

.love_story{
  // padding-top: 8rem !important;
  max-width: 624px;
  .love_header{
    text-align: center;
  }
  .context_left{
    display: flex !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .text_content{
      flex:  1 !important;
      display:  flex !important;
      flex-direction: column;
      justify-content: center;
      h3, h6, p{
        text-align: left;
        margin-left: 0 !important;
      }
      p{
        display: none !important;
      }
    }
    .image_wrapper_love{
      margin-right: 15px !important;
      width: 7rem;
      height: 7rem;
      margin-top: .5rem !important;
      margin-bottom: .5rem !important;
    }
  }

  .context_right{
    display: flex !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .text_content{
      flex:  1 !important;
      display:  flex !important;
      flex-direction: column;
      justify-content: center;
      h3, h6, p{
        text-align: right;
        margin-left: 0 !important;
      }
      p{
        display: none !important;
      }
    }
    .image_wrapper_love{
      margin-left: 15px !important;
      width: 7rem;
      height: 7rem;
      margin-top: .5rem !important;
      margin-bottom: .5rem !important;
    }
  }
}

.react-photo-album--photo{
  transition: all .5s;
  opacity: 0 !important;
  transform: translate(-50%);
  display:  block !important;
  &:nth-child(1){
    transform: translate(50%);
  }
  img{
    object-fit: contain !important;
    // background-color: #8b7580 !important;
  }
}

.react-photo-album--column{

  img{
    object-fit: contain !important;
    background-color: #8b7580 !important;
  }
}

.gallery_view{
  max-width: 768px !important;
  margin: auto;
}

div.do_hide.animated.zoomIn{
  display:  none !important;
}

.gallery_box{
  padding-top: 20px;
}

.mandiri_test{
  height: 50px !important;
}