$darkPurple: rgb(61,3,59);
$purple: rgb(122, 5, 118);
$lightPurple: #8B07C4FF;
$lightBlue: #0AF;

$primaryColor : #004b7c;
$primaryShadow: #0073b1;
$notifyOk : #469a1f;
$notifyWarning: #ffda4b;
$notifyAlert: #c8352b;
$notifyAlertShadow: 0 0 4px #c8352b;
$notActive: #5a5a5a;
$brownColor:#717171;

$dashedColor: #b6b6b6;
$sidebarPrimary: #fafafa;

body{
  --main-color-semi-light: #f1f1f1;
  --main-color-light: #fff;
  --main-color: #0073b1;
  --main-color-dark: #004b7c;
  --toobox-bg: #717171;
  --toobox-bg-dark: #5a5a5a;
  --toolbox-bg-more-dark: #ffffff2b;
  --font-color-light: #fff;
  --font-color-semi-dark: #f6f6f6;
  --font-color-dark: #333;
  --font-color-black: #000;
  --dashed-color-light: #fafafa;
  --dashed-color-dark: #b6b6b6;
  --notify-alert:#c8352b;
  --niotify-warning: #ffda4b;
  --notify-passed: #469a1f;
  --main-bg: #f3f3f3;
  --bg-nav-bar: #0073b1;
}

@mixin transformScale($value){
  transform: scale($value);
  -moz-transform: scale($value);
  -wekit-transform: scale($value);
  -o-transform: scale($value);
}