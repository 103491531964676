.table-box{
    display: block;
    width: 100%;
    height: auto;
    line-height: 1.5;
    .loading-item-td{
        position: relative;
        background-color: #fff;
        padding: 20px 15px;
        flex: 1;
        margin-right: 3px;
        font-weight: 500;
        min-height: 70px;
        margin-top: 3px;

        background-color: #f1f1f1;
        .load-bar{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            @extend .loader-anim;
            &:last-child{
                margin-right: 0px;
            }
        }
    }
    .table-header{
        box-shadow: 0 2px 9px rgba(0,0,0,.08);
        .table-header-inside{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .header-item{
                background-color: #f1f1f1;
                padding: 15px;
                flex: 1;
                margin-right: 3px;
                font-weight: 800;
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }

    .numbering{
        // width: 50px;
        flex: 0 !important;
        // min-width: 80px;
    }

    .table-list{
        box-shadow: 0 2px 9px rgba(0,0,0,.08);
        color: #6b6b6b;
        margin-bottom: 1px;
        .table-list-inside{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list-item{
                background-color: #fff;
                padding: 20px 15px;
                flex: 1;
                margin-right: 3px;
                font-weight: 500;
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }
    .unflex{
        flex: 0 !important;
    }
}

.table-list-sata-notfound{
    padding: 15px;
}