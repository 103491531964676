.circle-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(0,0,0,.2);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-rotate{
        fill: #fff;
        width: 50px;
        height: 50px;
        animation: animLoader .5s infinite;
    }
}

@keyframes animLoader{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.loader-anim{
    animation: loader_lirary_image 1s infinite;
    &.speed_one{
        animation-duration: 1s;
    }
    &.speed_two{
        animation-duration: 1.3s;
    }
    &.speed_three{
        animation-duration: .8s;
    }
}
  
@keyframes loader_lirary_image{
    0%{
      width: 0;
      left: 0;
      background: linear-gradient(90deg, #e9e9e9 84%, rgba(0,0,0,0) 100%);
    }
    64%{
      width: 100%;
      left: 0;
      background: linear-gradient(90deg, #e9e9e9 84%, rgba(0,0,0,0) 100%);
    }
    65%{
      width: 100%;
      right: 0;
      left: auto;
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #e9e9e9 23%);
    }
    100%{
      width: 0%;
      right: 0;
      left: auto;
      background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #e9e9e9 23%);
    }
}